const url = "http://imapserver.console-bot.com";
// Local development url:
// const url = "http://localhost:5000";

export const getMail = async props => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(props)
  };
  const response = await fetch(`${url}/getemails`, requestOptions);
  if (!response.ok) return Promise.reject(response.statusText);
  const emails = await response.json();
  return emails;
};

export const openMsg = async props => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(props)
  };
  const response = await fetch(`${url}/getmessage`, requestOptions);
  if (!response.ok) return Promise.reject(response.statusText);
  const message = await response.json();
  return message;
};
