import { useState, useEffect } from "react";
import queryString from "query-string";
import { getMail, openMsg } from "./helpers";
import DOMPurify from "dompurify";

function App() {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [messageLoading, setMessageLoading] = useState(false);
  const [errorFlow, setErrorFlow] = useState(false);
  const queryStringParams = queryString.parse(window.location.search);
  const {
    socksHost,
    socksPort,
    socksUser,
    socksPass,
    email,
    alias,
    imap_host,
    imap_login,
    imap_password
  } = queryStringParams || {};

  useEffect(() => {
    if (socksHost) {
      try {
        getMail({
          socksHost,
          socksPort,
          socksUser,
          socksPass,
          imap_host,
          imap_login,
          imap_password
        }).then(res => {
          if (res?.emails?.length) {
            setMessages(res?.emails);
          } else if (res?.error) {
            setErrorFlow(
              res.error
                .replaceAll("\r", "")
                .split("\n")

                .map(el => el.trim())
            );
          }
          setLoading(false);
        });
      } catch (e) {
        console.error("getMail ERROR: ", e, e.message);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!socksHost) return null;

  const openMessage = messageNumber => {
    setCurrentMessage(null);
    setMessageLoading(true);
    try {
      openMsg({
        socksHost,
        socksPort,
        socksUser,
        socksPass,
        imap_host,
        imap_login,
        imap_password,
        messageNumber
      }).then(res => {
        if (res?.message) {
          setCurrentMessage(res?.message?.Body);
        }
        setLoading(false);
        setMessageLoading(false);
      });
    } catch (e) {
      setMessageLoading(false);
      console.error("openMsg ERROR: ", e, e.message);
    }
  };

  return (
    <div className="app">
      <div className="header">
        <h3>
          Emails for <i>{email}</i> (alias: <i>{alias}</i>)
        </h3>
      </div>
      {loading ? (
        <p className="loading">Loading...</p>
      ) : messages ? (
        <div className="mailReader">
          <div className="messageBox">
            {messages.map((msg, i) => (
              <div
                className="message"
                key={msg.date + i}
                onClick={() => openMessage(msg.messageNumber)}
              >
                <div className="date">{msg.date}</div>
                <div className="from">{msg.from}</div>
                <div className="subject">{msg.subject}</div>
              </div>
            ))}
          </div>
          <div className="messageReader">
            {messageLoading ? (
              <div className="loading">Loading...</div>
            ) : currentMessage ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currentMessage)
                }}
              ></div>
            ) : null}
          </div>
        </div>
      ) : errorFlow ? (
        <div className="error-box">
          <div className="error-header">Service Error:</div>
          {errorFlow.map((msg, i) => (
            <div key={i + msg} className="error-string">
              {msg}
            </div>
          ))}
        </div>
      ) : (
        <h5>No Data</h5>
      )}
    </div>
  );
}

export default App;
